import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('ContentArea', {
    staticClass: "content-area d-flex flex-column align-center justify-center"
  }, [_c('ImagesSendwow', {
    attrs: {
      "height": "86"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mt-6"
  }, [_vm._v(_vm._s(_vm.$t('logoutMessage')))])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };